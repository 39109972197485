<template></template>
<script>
export default {
  name: 'text-chat',
  data() {
    return {
      door: false,
      status: this.value,
      ws: null,
    }
  },
  props: {
    user_id: {
      type: String
    },
    app_id: {
      type: String
    },
    value: {
      type: Boolean
    },
    group_id:{
      type:String
    }
  },
  watch: {
    value(e) {
      this.status = e
    }
  },
  mounted() {
    this.onConnect()
  },
  methods: {
    onConnect() {   
      console.log(this.user_id) 
      if (!this.user_id) {
        return
      }

      //建立连接
      let ws = new WebSocket("wss://team-chat.learningfirst.cn:9000");
      ws.onerror = (err) => {
      };
      this.ws = ws

      //对ws进行设置

      //成功建立的操作
      ws.onopen = () => {
        console.log('APP_ID:'+this.app_id)
        console.log('USER_ID:'+this.user_id)
        console.log('GROUP_ID:'+this.group_id)
        this.$message({
            message:'Connection Success',
            type:'success',
            duration:5000,
            showClose:true
        })
        this.changeStatus(true)
        ws.send(`user&${this.group_id}&${this.user_id}`);
      };
      //关闭连接的操作
      ws.onclose = () => {
        this.changeStatus(false)
      }
      //接收到消息的操作
      ws.onmessage = (e) => {
        if(typeof e.data === 'string'){


          let data = e.data;


          console.log(111111,data)
           //同步问题
          if (data.indexOf("correctQuestionSync") !== -1) {
            let obj = data.split("&")[1];
            obj = JSON.parse(obj);
            this.$emit("correctQuestionSync", obj);
            //同步图片
          } else if (data.indexOf("correctSessionPicSync") !== -1) {
            let obj = data.split("&")[1];
            obj = JSON.parse(obj);
            this.$emit("correctSessionPicSync", obj);
          }else if(data.indexOf("answerSync") !== -1){

          }else if(data.indexOf("updateCheckIn") !== -1){
            this.$emit("updateCheckIn");
          }else{



            this.$message({
                message:'System message：'+e.data,
                duration:5000,
                showClose:true
            })
          }
        }
      };

    },
    offConnect() {
      if (this.ws) {
        this.ws.close();
        this.changeStatus(false)
      }
    },
    changeStatus(bool) {
      this.status = bool
      this.$emit('input', bool)
    },
  },
  destroyed() {
    console.log('Socket Closed')
    this.offConnect()
    clearInterval(this.statusTimer)
  }
}
</script>