/**
 * @param {numeber} currentY 需要移动的dom当前位置离网页顶端的距离
 * @param {number} targetY 需要移动的dom当前位置离要移到的位置的距离
 */
function scroll(currentY, targetY,element) {
    // 计算需要移动的距离
    let needScrollTop = targetY - currentY
    console.log('needScrollTop :' + needScrollTop)
    let _currentY = currentY
    setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10)
        console.log('dist :' + dist)
        _currentY += dist
        console.log('_currentY :' + _currentY)
        element.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
            console.log('1111 _currentY:' + _currentY +" targetY:" + targetY)
            scroll(_currentY, targetY,element)
        } else {
            console.log('2222')
            element.scrollTo(_currentY, targetY)
        }
    }, 1)
}

export default scroll
